Drupal.behaviors.siteWideFooterFormatterV1 = (function ($) {
  var $html = $();
  var $template = $();
  var linksToNavigate = 'a, button, input, select, textarea, iframe, object, embed, *[tabindex], *[contenteditable]';
  var tabbableElements =
    'a:tabbable, button:tabbable, input:tabbable, select:tabbable, textarea:tabbable, iframe:tabbable, object:tabbable, embed:tabbable, *[tabindex]:tabbable';
  var noTabbableElements = '[tabindex=-1], [disabled], [aria-hidden=true]';
  var behavior = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      $html = $('html');
      $template = $('.js-sitewide-footer-formatter-v1', context);

      $(linksToNavigate, $template)
        .not(noTabbableElements)
        .on('keydown', function (event) {
          var $this = $(this);
          var key = event.key;
          var $tabbableElements = $(tabbableElements, $template).not(noTabbableElements);
          var index = $tabbableElements.index($this);
          var target;

          $html.addClass('ada-enabled');
          switch (key) {
            case 'ArrowLeft':
              target = index === 0 ? -1 : index - 1;
              break;
            case 'ArrowUp':
              target = index === 0 ? -1 : index - 1;
              break;
            case 'ArrowRight':
              target = index === $tabbableElements.length - 1 ? 0 : index + 1;
              break;
            case 'ArrowDown':
              target = index === $tabbableElements.length - 1 ? 0 : index + 1;
              break;
          }

          if (target !== null && target !== undefined) {
            focusElement(target);
          }
        });

      function focusElement(index) {
        var $prevActiveElem = $(document.activeElement);

        // Removing class used for ADA on US.
        $prevActiveElem.removeClass('custom-outline');
        $(tabbableElements, $template).not(noTabbableElements).eq(index, 10).focus();
      }

      $(document).on(
        'mousemove',
        _.debounce(function () {
          // If mouse movement is detected then remove ada class.
          if ($html.hasClass('ada-enabled')) {
            $html.removeClass('ada-enabled');
          }
        }, 200)
      );
    }
  };

  return behavior;
})(jQuery);
